import AddCust from "../customer/addCust";

const CustomerRegestration = () => {
	return (
		<>
			<AddCust />
		</>
	);
};

export default CustomerRegestration;
