import { Card, Col, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReturnSaleInvoiceProductList from "../../popUp/returnSaleProductList";
import ColVisibilityDropdown from "../../Shared/ColVisibilityDropdown";

const ReturnSaleInvoiceList = ({ list }) => {
  const [columnsToShow, setColumnsToShow] = useState([]);

  const columns = [
    {
      id: 1,
      title: "View Details",
      dataIndex: "returnSaleInvoiceProduct",
      key: "returnSaleInvoiceProduct",
      render: (returnSaleInvoiceProduct) => (
        <ReturnSaleInvoiceProductList list={returnSaleInvoiceProduct} />
      ),
    },
    {
      id: 2,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      id: 3,
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },

    {
      id: 4,
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      id: 5,
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);
  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr.map((i) => ({ ...i, key: i.id }));

  return (
    <Row>
      <Col span={24} className="mt-2">
        <Card
          className="header-solid h-full"
          bordered={false}
          title={[
            <h6 className="font-semibold m-0 text-center">
              Return Sale Information
            </h6>,
          ]}
          bodyStyle={{ paddingTop: "0" }}
        >
          {list && (
            <div style={{ marginBottom: "30px" }}>
              <ColVisibilityDropdown
              options={columns}
              columns={columns}
              columnsToShowHandler={columnsToShowHandler}
            />
            </div>
          )}
          <div className="col-info">
            <Table
              scroll={{ x: true }}
              loading={!list}
              columns={columnsToShow}
              dataSource={list ? addKeys(list) : []}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ReturnSaleInvoiceList;
